const cookCont = document.querySelector('.cookie_container');
const cookBtn = document.querySelector('.cookie_container--button');

function activeCookie() {
    cookCont.classList.add('active');
}
function btnClick() {
    cookCont.classList.remove('active');
    setCookie("encuestasCookies", "yes", 64);
}
cookBtn.addEventListener('click', btnClick);

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

let mycookie = getCookie("encuestasCookies");
if (mycookie != "yes") {
    activeCookie();
}
